import React from 'react';
import styles from './carousel.module.scss';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'next/image';
import Link from 'next/link';
import CONST_REDIRECTS from "@/shared/consts/CONST_REDIRECTS.json"

const imageLoader = ({ src }:{src:string}) => {
  return `${src}`;
};


const CarouselB2b = () => {
  return (
    <>
      <div className={styles['container']}>
        <Carousel
        style={{ width: '100%'}}
        prevIcon={<img src="/arrowLeft.svg"
        alt="Custom Prev Icon" />}
        nextIcon={<img src="/arrowRight.svg" alt="Custom Next Icon" />}
        >
          <Carousel.Item>
            <Link href={CONST_REDIRECTS.ANNOUNCEMENTS}>
                <div className={styles['img-carousel']} >
                <div className={styles["img-text"]}>
                </div>
                    <Image src={"https://res.cloudinary.com/dlwdq84ig/image/upload/v1728675693/dhektn3ra6ypikujhtof.png"} width={504} height={201} alt="Banner1" loading='lazy' loader={imageLoader}/>
            </div>
            </Link>
          </Carousel.Item>
          <Carousel.Item>
            <div className={styles['img-carousel']} >
              <div className={styles["img-text"]}>
              </div>
              <Image src={"https://res.cloudinary.com/dlwdq84ig/image/upload/v1726183440/shb7aagkvirmzc62w0ct.webp"} width={504} height={201} alt="Banner1" loading='lazy' loader={imageLoader}/>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className={styles['img-carousel']} >
              <div className={styles["img-text"]}>
              <p>¡Descarga tu manual!</p>
                <p>Descarga el manual de
                  uso del nuevo portal
                  desde nuestra sección
                  de comunicados.
                </p>
                <Link href={CONST_REDIRECTS.ANNOUNCEMENTS} ><div>
                  <span style={{color:"white",fontSize:"0.7rem",textDecoration:"underline",cursor:"pointer"}}>¡Explora ahora!</span></div>
                </Link>
              </div>
              <Image src={"https://res.cloudinary.com/dlwdq84ig/image/upload/v1711561038/jmram1cqwk7a1kf5ahjz.webp"} width={504} height={201} alt="Banner1" loading='lazy' loader={imageLoader}/>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
};

export default CarouselB2b;
